$trigger-width: 150px;

.message-template-list {
  .trigger {
    font-weight: 600;
  }

  .channel {
    display: inline-block;
    width: 15px;
  }

  .delivery-offset {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  .message-template-list .delivery-offset {
    display: block;
    min-width: 35px;
    text-align: right;
  }
}

@include media-breakpoint-up(lg) {
  .trigger {
    flex: 0 0 $trigger-width;
  }

  .add_message_button {
    margin-left: $trigger-width;
  }
}
