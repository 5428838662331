// Page footer

.ti-footer {
  color: $body-color;
  height: 100%;

  a {
    color: $body-color;
  }

  a:hover {
    color: $ti-blue-two;
  }
}

// Sidebar Page footer

.wrapper .footer-container {
  padding: 0;
}

// Single Page footer

$footer-narrow-height: 76px;
$footer-mid-height: 45px;
$footer-wide-height: 45px;
$inverse-footer-narrow-height: (-1 * $footer-narrow-height);
$inverse-footer-mid-height: (-1 * $footer-mid-height);
$inverse-footer-wide-height: (-1 * $footer-wide-height);


// Wrapper for page content to push down footer
#sticky-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;

  // Negative indent footer by it's min height
  margin: 0 auto $inverse-footer-narrow-height;
}

#sticky-footer {
  border-top: 1px solid $ti-gray-one;
  font-size: 14px;
}

// Set the fixed height of the footer here
#sticky-push,
#sticky-footer {
  min-height: $footer-narrow-height;
}

// Stack footer menu items horizontally on non-phone screens
@include media-breakpoint-up(sm) {
  #sticky-wrapper {
    margin-bottom: $inverse-footer-mid-height;
  }

  #sticky-push, #sticky-footer {
    min-height: $footer-mid-height;
  }
}

@include media-breakpoint-up(lg) {
  #sticky-wrapper {
    margin-bottom: $inverse-footer-wide-height;
  }

  #sticky-push, #sticky-footer {
    min-height: $footer-wide-height;
  }
}
