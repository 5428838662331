#inapp-layout {
  .content-heading {
    font-size: 1.5rem;
    line-height: $headings-line-height;
    color: #929292;
    margin: 20px 0;
    font-weight: normal;
  }

  .btn {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
