.job_services_and_discounts_table {
  .footer {
    text-align: right;
    margin-bottom: 5px;
  }

  .value_box {
    margin-left: 10px;
    flex: 0 0 80px;
    text-align: right;
  }

  .line_item {
    margin-bottom: 0.5rem;
  }

  .line_item:last-of-type {
    margin-bottom: 0;
  }

  .service-title {
    font-weight: 600;
    font-size: 1rem;
    color: $ti-blue-one;
  }

  .total-row, .discount-title {
    font-weight: 600;
    font-size: 1rem;
  }
}
