.handle-placeholder {
  width: 30px;
}

.x-box {
  margin-left: 0.5rem;
  width: 35px;
}

@include media-breakpoint-up(lg) {
  .treat-as-client-header {
    width: 60px;
  }
}
