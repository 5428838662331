$ti-brand-blue: #000c9a;
$ti-blue-one: #19527E;
$ti-blue-two: #0078ea;
$ti-blue-three: #000865;
$ti-blue-four: #999CD6;
$ti-blue-five: #000648;
$ti-blue-six: #000430;
$ti-blue-seven: #12185E;
$ti-blue-eight: #111B8C;
$ti-blue-nine: #8083B3;
$ti-blue-ten: #004dd0; // Not in mobile
$ti-blue-eleven: #222FC7; // Not in mobile
$ti-blue-twelve: #B5B7F2; // Not in mobile
$ti-blue-thirteen: #5659B3; // Not in mobile

$ti-dark-text: #313131;
$ti-light-text: #ACACAC;
$ti-drop-shadow: rgb(0 0 0 / 5%);
$ti-gray-one: #EDF1F5;
$ti-gray-two: rgb(185 196 212 / 30%);
$ti-gray-three: #CBD5E3;
$ti-gray-four: #A2B6D8;
$ti-gray-five: #F5F7F9;
$ti-gray-six: #DDE6E9;
$ti-green: #28A745;
$ti-green-two: #218939;
$ti-green-three: #73ad36;
$ti-aqua: #09c;
$ti-aqua-dark: #0084BC;
$ti-purple: #7B5E91;
$ti-purple-dark: #5D3F73;
$ti-white: #fff;
$ti-yellow: #F9D102;

// Add the color palette to the bootstrap theme so that we get the auto generated color classes
$custom-theme-colors: (
  "ti-brand-blue":      $ti-brand-blue,
  "ti-blue-one":        $ti-blue-one,
  "ti-blue-two":        $ti-blue-two,
  "ti-blue-three":      $ti-blue-three,
  "ti-blue-four":       $ti-blue-four,
  "ti-blue-five":       $ti-blue-five,
  "ti-blue-six":        $ti-blue-six,
  "ti-blue-seven":      $ti-blue-seven,
  "ti-blue-eight":      $ti-blue-eight,
  "ti-blue-nine":       $ti-blue-nine,
  "ti-blue-ten":        $ti-blue-ten,
  "ti-blue-eleven":     $ti-blue-eleven,
  "ti-blue-twelve":     $ti-blue-twelve,
  "ti-blue-thirteen":   $ti-blue-thirteen,

  "ti-dark-text":       $ti-dark-text,
  "ti-light-text":      $ti-light-text,
  "ti-drop-shadow":     $ti-drop-shadow,

  "ti-gray-one":        $ti-gray-one,
  "ti-gray-two":        $ti-gray-two,
  "ti-gray-three":      $ti-gray-three,
  "ti-gray-four":       $ti-gray-four,
  "ti-gray-five":       $ti-gray-five,
  "ti-gray-six":        $ti-gray-six,

  "ti-green":           $ti-green,
  "ti-green-two":       $ti-green-two,
  "ti-green-three":     $ti-green-three,

  "ti-aqua":            $ti-aqua,
  "ti-aqua-dark":       $ti-aqua-dark,
  "ti-purple":          $ti-purple,
  "ti-purple-dark":     $ti-purple-dark,
  "ti-white":           $ti-white,
  "ti-yellow":          $ti-yellow
);
