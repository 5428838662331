
body {
  font-size: 15px;
}

.ti-h1 {
  font-weight: 600;
  font-size: 1.1rem;
}

.ti-h3 {
  font-weight: 600;
  font-size: 0.8rem;
}

.big-badge {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 0.25rem;
  line-height: 1rem;
  font-size: 12px;
  font-weight: 700;
}

