.manage_people_form {
  #people-control-row {
    ul.pagination {
      margin-bottom: 0;
      margin-left: 1rem;
    }

    margin-bottom: 1rem;
  }

  .sort-field {
    font-size: 17px;
    font-weight: 600;
  }
}
