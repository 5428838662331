@use "sass:color";

// Colors

@import 'tapinspect_color_palette';

// Bootstrap overrides

$primary: $ti-blue-two;
$success: #28a745; // Bootstrap 4 value
$info: #17a2b8; // Bootstrap 4 value
$body-bg: $ti-gray-one;
$body-color: $ti-dark-text;
$input-color: $body-color;
$input-placeholder-color: $ti-light-text;
$card-border-color: $ti-gray-two;
$card-cap-bg: white;
$hr-border-color: $ti-gray-two;
$border-color: $ti-gray-two;
$list-group-border-color: $ti-gray-two;
$dropdown-border-color: $ti-gray-two;
$dropdown-link-hover-bg: $ti-gray-five;
$enable-validation-icons: false;
$form-label-font-weight: 700 !important;

// Misc

$light-green-hover-color: #c2ff18;
$mid-green-hover-color: color.adjust($light-green-hover-color, $lightness: -15%) !default;
$grey-body-bg-color: #e9e9e9;
$grey-body-text-color: #666;
$light-grey-body-text-color: #919da8;
$link-color: $ti-blue-two;
$link-hover-color: color.adjust($ti-blue-two, $lightness: -15%);
$alert-notice-text-color: #468847;
$alert-notice-bg-color: #dff0d8;
$alert-notice-border-color: #d6e9c6;
$ti-table-text-hover-color: #000;
$ti-table-shaded-bg-color: #f9f9f9;
$body-text-emphasis-color: #777;
$bold-section-title-color: #333;
$bold-section-title-shadow-color: #e4e4e4;
$alternating-bg-light-color: #fcfcfc;
$alternating-bg-dark-color: #f6f6f6;
$needs-attention-color: #fcf8e3;


// Pricing

$pricing-green: #64AD16;
$pricing-grey: #9B9B9B;


// Buttons

$red-text-button-color: #ad1664;


// Navbar Brand margin

$navbar-brand-margin-end: 0.5rem;
