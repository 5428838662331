.recent-activity-container {
  #placeholder {
    height: 240px;
  }

  .icon_container {
    width: 40px;
    min-height: 40px;
    color: white;
  }

  .data-cell {
    padding: 5px 2px;
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
}
