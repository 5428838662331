.slide-up {
    animation: slide-up 0.5s ease;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
