#calendar-container {
  box-shadow: 0 2px 1px $ti-drop-shadow;

  .fc-view {
    background-color: white;
  }

  // Prevent the event view stack from stealing mouse events needed to change the day cell background color. Note that this probably breaks event click/drag events but we don't care for our purposes. Simply re-enable pointer events for those classes that need it
  .fc-daygrid-event-harness {
    pointer-events: none;
  }

  // All of the dates and column headers are actually a tags, so without this it assumes the generic link color
  a {
    color: $body-color;
  }
}

.job-assignment-filter {
  font-size: 1rem;
  line-height: 1rem;

  .form-check {
    line-height: 1.5;
  }
}

.form-check-label::before {
  background-color: transparent;
}

.calendar-title-month {
  font-size: 1.5rem;
  font-weight: 700;
}

.calendar-title-etc {
  margin-left: 0.25rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.calendar-header {
  background-color: white;
  padding: 1.25rem;
  border-top: 1px solid rgb(0 0 0 / 12.5%);
  border-left: 1px solid rgb(0 0 0 / 12.5%);
  border-right: 1px solid rgb(0 0 0 / 12.5%);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.calendar-footer {
  background-color: white;
  padding: 1.25rem;
  border-bottom: 1px solid rgb(0 0 0 / 12.5%);
  border-left: 1px solid rgb(0 0 0 / 12.5%);
  border-right: 1px solid rgb(0 0 0 / 12.5%);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.month-container {
  .day-column {
    margin-top: 1.5rem;

    .day-title {
      font-size: 1.1rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .date-title {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
}

@media (min-width: 992px) {
  .month-container {
    .day-column {
      width: 400px;
      margin-top: 0;
    }
  }
}
