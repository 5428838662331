.job-photo-card {
  width: auto;
  height: 240px;
  margin-bottom: 1.25rem;
  position: relative;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.25rem;
  }

  .job-state-badge {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  .camera-button {
    position: absolute;
    right: 5px;
    top: 10px;

    &:hover .fa-circle {
      color: $ti-gray-five !important;
    }
  }
}

.job-show-container {
  .left-col-notes-and-messages {
    display: none;
  }
}

.btn-fake {
  transition: none;
}

#inspection-time-card:hover .btn-fake {
  background-color: #d4dce7;
  border-color: #ccd5e3;
}

// Use a two column layout on larger screens
@media (min-width: 992px) {
  .job-show-container {
    .left-col {
      width: 450px;
    }

    .left-col-notes-and-messages {
      display: block;
    }
  }

  .right-col {
    display: none;
  }
}

// Use a three column layout on extra-large screens
@media (min-width: 1350px) {
  .container-fluid.content-wrapper {
    .job-show-container {
      .right-col {
        display: block;
        width: 350px;
      }

      .left-col-notes-and-messages {
        display: none;
      }
    }
  }
}

// Use a larger breakpoint when a sidebar is present
@media (min-width: 1650px) {
  .section-container {
    .job-show-container {
      .right-col {
        display: block;
        width: 350px;
      }

      .left-col-notes-and-messages {
        display: none;
      }
    }
  }
}
