// Referrals page

.big_icon {
  font-size: 52.0px;
}

.referral_title {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.referral_line {
  margin-top: 12px;

  .big_text {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-shadow: 0 0 10px rgb(255 255 255 / 50%);
    color: $ti-green;
  }
}

@include media-breakpoint-up(sm) {
  .referral_title {
    font-size: 32px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .referral_line {
    margin-top: 20px;

    .big_text {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
    }
  }
}
