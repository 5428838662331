.payments-statement {
  .card-footer, .card-header {
    font-weight: 700;
  }

  .value_box {
    flex: 0 0 75px;
    text-align: right;
  }

  .date_box {
    flex: 0 0 105px;
  }

  .type {
    flex: 0 0 80px;
    font-weight: 700;
  }

  .list-group-item {
    page-break-inside: avoid;
  }
}
