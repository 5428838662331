.rendered-invoice {
  .invoice-logo {
    max-width: 350px;
    max-height:  175px;
    width: auto;
    height: auto;
  }

  .big-bottom-margin {
    margin-bottom: 40px;
  }

  .header {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid $ti-gray-two;
  }

  .discount-separator {
    margin-bottom: 0.5rem;
    border-bottom: 2px solid $ti-gray-two;
  }

  .footer {
    border-top: 2px solid $ti-gray-two;
    padding-top: 0.75rem;
    text-align: right;
    margin-bottom: 5px;
  }

  .value_box {
    margin-left: 10px;
    width: 5.5rem;
    text-align: right;
  }

  .line_item {
    margin-bottom: 0.5rem;
  }

  .line_item:last-of-type {
    margin-bottom: 0;
  }

  .big_price {
    font-size: 40px;
    line-height: 1.0;
  }
}

@include media-breakpoint-up(md) {
  .rendered-invoice {
    .big-bottom-margin {
      margin-bottom: 75px;
    }
  }
}
