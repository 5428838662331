.payments-nav-container {
  display: none;
  width: 225px;
}

.admin-payments-nav-container {
  display: none;
  width: 300px;
}

.payments-nav-bar {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  .col {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

// when the navigation container is present, if the page has not specified any additional buttons to showing the content heading, a spacer is included so that the navigation container doesn't jump left to right when switching pages
.payments-nav-spacer {
  width: 43px;
}

@media (min-width: 768px) {
  .payments-nav-container, .admin-payments-nav-container {
    display: block;
  }
}
