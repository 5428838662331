#week-calendar-container {
  box-shadow: 0 2px 1px $ti-drop-shadow;

  .fc-view {
    background-color: white;
  }

  // All of the dates and column headers are actually a tags, so without this it assumes the generic link color
  a {
    color: $body-color;
  }

  .current-time {
    background-color: $ti-gray-five;
    color: #9B9B9B;
    position: relative;
    cursor: pointer;
    text-align: center;
  }

  .hover-row {
    height: 100%;
  }
}
