.section-list {
  .indent-1 {
    margin-left: 16px !important;
    margin-left: 1rem !important;
  }

  .indent-2 {
    margin-left: 32px !important;
    margin-left: 2rem !important;
  }

  .indent-3 {
    margin-left: 48px !important;
    margin-left: 3rem !important;
  }
}

@media (min-width: 768px) {
  .inspection-section {
    .item-title {
      width: 200px;
      margin-right: 0.50rem !important;
    }
  }
}
