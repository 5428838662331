.photo_frame {
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
  margin-bottom: 2px;
}

.broken_photo_placeholder {
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 2px;
  color: white;
  width: 160px;
  height: 160px;
  background-color: #555;
  text-align: center;
}
