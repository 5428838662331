.svg-frame {
  position: relative;
  display: inline-block;

  canvas {
    display: block;
    height: 100%;
    visibility: hidden;
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    fill: inherit;
  }
}
