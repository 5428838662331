$preview-accent-height: 17px;
$preview-sidebar-width: 120px;
$preview-sidebar-width-collapsed: 20px;

.client-portal-preview {
  border: 1px solid #ccc;

  .top-accent {
    height: $preview-accent-height;
  }

  .container-body {
    position: relative;
    display: flex;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: $preview-accent-height;
    width: $preview-sidebar-width-collapsed;
    color: white;
  }

  .main-body {
    background: $ti-gray-one;
    margin-left: $preview-sidebar-width-collapsed;
    padding: 20px;

    .card {
      border: 1px solid rgb(0 0 0 / 12.5%);
      box-shadow: none;
    }

    .logo-container {
      width: 150px;
      margin-bottom: 0.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .client-portal-preview {
    .sidebar {
      width: $preview-sidebar-width;
      padding: 20px;
    }

    .main-body {
      margin-left: $preview-sidebar-width;
    }
  }
}
