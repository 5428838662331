.job-list-pagination {
  ul {
    margin-bottom: 0;
  }
}

.job-list {
  .clickable:hover {
    background-color: $ti-gray-five;
  }

  .datetime_box {
    width: 95px;

    .date {
      font-weight: 700;
    }
  }

  #property-address {
    font-weight: 700;
  }

  .status_box {
    width: 30px;
  }
}
