.content-heading.wizard-content-heading {
  .title {
    font-size: 1.125rem;
  }
}

.content-wrapper {
  .content-heading.wizard-content-heading {
    background-color: $ti-brand-blue;
  }
}

.wizard_progress_indicator {
  display: flex;
  margin-bottom: 1rem;

  .step {
    width: 24px;
    height: 24px;
    border: 3px solid $ti-blue-twelve;
    border-radius: 12px;
    z-index: 10;
  }

  .step.active {
    background: white;
    border: 3px solid white;
  }

  .link {
    height: 5px;
    width: 24px;
    margin: auto -1px;
    background: $ti-blue-thirteen;
  }

  .link.active {
    background: white;
  }
}

.wizard-info-card {
  border-left: 15px solid $ti-green;
}

@media (min-width: 768px) {
  .wizard_progress_indicator {
    margin-right: 40px;
    margin-bottom: 0;
  }
}
