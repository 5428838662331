$green-color: #548b00;
$top-gradient-color: #fefefe;
$bottom-gradient-color: #ccc;
$drop-shadow-color: #bbb;

.v1_pickup_wrap {
  background: linear-gradient(to bottom, $top-gradient-color, $bottom-gradient-color);

  .container {
    max-width: 970px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  a {
    color: $green-color;
    text-decoration: underline;
    line-height: 1.75;
    &:hover { color: $green-color; }
  }

  p {
    color: #000;
    line-height: 1.4;
  }

  #headergap {
    height: 45px;
  }

  .topbox {
    margin-bottom: 20px;
    word-wrap: break-word;
  }

  .headline {
    font-weight: 700;
    font-size: 36px;
    color: #333;
    letter-spacing: -0.025em;
    line-height: 1.2;
  }

  .address {
    font-size: 28px;
    color: #666;
    letter-spacing: -0.025em;
    line-height: 1.2;
  }

  .inspection_date {
    font-size: 15px;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.076em;
    line-height: 1.5;
  }

  .download_link {
    margin: 10px 0 0;

    .small_arrow {
      margin: 0 0 5px;
    }
  }

  .picture_frame {
    background-color: white;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 15px;
    box-shadow: -7px 7px 4px $drop-shadow-color;

    .picture {
      max-height: 500px;
      overflow: hidden;

      img {
        width: 100%;
        max-height: auto;
      }
    }
  }

  @media (min-width: 767px) {
    .picture_frame {
      .picture {
        img {
          margin-top: -10%;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .picture_frame {
      .picture {
        img {
          margin-top: -20%;
        }
      }
    }
  }
}

#v1-pickup-footer {
  background-color: #000;

  .footertext {
    margin: 15px 0;
    color: $green-color;
    text-align: center;
  }

  a {
    color: $green-color;
    text-decoration: underline;
    line-height: 1.75;
    &:hover { color: $green-color; }
  }
}

// Styles that only apply when previewing the pickup page as a logged in user

.pickup_preview_container {
  .v1_pickup_wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.business_card {
  background-color: white;
  border: 2px solid #ccc;
  text-align: center;
  font-size: 15px;
  color: #666;
  line-height: 1.4;

  a { /* stylelint-disable-line no-descending-specificity */
    color: #666;
    text-decoration: underline;
    line-height: 1.4;
  }

  .name {
    font-size: 18px;
    line-height: 1.3;
    font-weight:bold;
  }

  .company {
    font-weight:bold;
  }

  .business_card_text {
    margin: 20px;
  }

  img { /* stylelint-disable-line no-descending-specificity */
    margin-bottom: 10px;
  }
}
