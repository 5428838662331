.trix-preview-container {
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  border: 1px solid $card-border-color;
  padding: 0.5rem;
}

.small-trix .trix-preview-container {
  height: 25vh;
  max-height: 25vh;
}

.trix-editor-with-preview-container, .trix-editor-wrapper {
  position: relative;
}

.trix-raw-editor {
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100%;
}

.trix-editor-with-preview-container #raw-editor-toggle {
  position: absolute;
  top: 0;
  right: 0;
}
