.propay_form_guide {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  text-align: center !important;
  font-size: 1.125rem;
}

@include media-breakpoint-up(md) {
  .propay_form_guide {
    font-size: 1.5rem;
  }
}
