// Inspections Page

.no_content_bar {
  color: #ccc;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  padding: 40px;
}

.time_period_bar {
  background: linear-gradient(to bottom, #c9c9c9, #7b7b7b);
  border-bottom: 1px solid #212f34;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 0 1px 0 #e4e4e4;
  padding: 10px 15px;
}

.report_list_bg {
  background: linear-gradient(to bottom, #333, #000);
  background-attachment: fixed;

  .ti-footer {
    color: #aaa;

    a {
      color: #aaa;
    }
  }

  #sticky-footer {
    border-top: 1px solid #333;
  }
}

.report_search_bar {
  margin: 15px 0;
}

.report_cell {
  height: 250px;
  width: 340px;
  position: relative;
  display: inline-block;
  margin-right: -3px;
  margin-bottom: -5px;
  border: 1px solid #7b7b7b;
}

.report_cell_responsive {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 240px;
  border: 1px solid #7b7b7b;
  background-color: #000;
  overflow: hidden;

  img.tile_background {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.report_list .report_cell_responsive {
  margin-bottom: -3px;
}

@media (min-width: 480px) {
  .report_list .report_cell_responsive {
    width: 49.5%;
  }
}

@media (min-width: 768px) {
  .report_list .report_cell_responsive {
    width: 33%;
  }
}

.clickable_div {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left: 0;

  /* edit: added z-index */
  z-index: 1;
}

.clickable_div:hover {
  background-color: rgb(255 255 255 / 25%);
}

.report_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 340px;
}

.report_desc {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  text-align: left;
  line-height: 1.2;
  padding: 6px 30px 6px 15px;
  background-color: rgb(0 0 0 / 75%);

  p {
    margin: 0;
  }

  #client-name {
    color: white;
    font-size: 16px;
    font-weight: 400;
  }

  #property-address {
    color: white;
    font-size: 17px;
    font-weight: 900;
  }

  .inspection-date {
    color: #999;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .arrow {
    position: absolute;
    bottom: 50%;
    right: 10px;
    margin-bottom: -7px;
  }
}

.report_loader {
  display: none;
  margin: 20px 0;
  height: 60px;
}

.picture_frame {
  position: relative;
  display: inline-block;
  margin: 2px;
}
