.message-list {
  .channel {
    display: inline-block;
    width: 15px;
    margin-right: 0.5rem;
  }

  .line-clamp {
    overflow: hidden;
    display: box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
