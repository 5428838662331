.inspection-section {
  .not-inspected {
    background-color: $grey-body-bg-color;
  }

  .list-group-item {
    .card {
      margin-top: 1.25rem;
      margin-bottom: 0;
    }
  }
}
