/* stylelint-disable no-descending-specificity */
$sidebar-item-color: $body-color;
$sidebar-item-color-active: $ti-blue-two;

// Items
.sidebar-nav {
    margin-top: 15px;
    font-size: 16px;

    > li {
        > a, > .nav-item {
            color: $sidebar-item-color;

            &:focus, &:hover {
                color: $sidebar-item-color-active;
            }

            // Item icon
            > em {
                color: $sidebar-icon-color;
            }

        }

        // Active item state
        &.active, &.open {
            &, > a, > .nav-item, .sidebar-nav {
                background-color: $sidebar-item-bg-active;
                color: $sidebar-item-color-active;
            }

            > .nav-item > em, > a > em {
                color: $sidebar-icon-color-active;
            }

        }

        &.active {
            border-left-color: $sidebar-item-color-active;
        }

    }

}

.sidebar .ti-aside-header {
    padding: 0 8px 8px;
    text-align: center;

    .organization-name {
        font-size: 16px;
    }

    .badge {
        display: inline-block;
    }
}

@media only screen and (min-width: $tablet-landscape) {
    .sidebar:not(.show-scrollbar) {
        .ti-aside-header {
            margin-right: 17px;
        }
    }
}
/* stylelint-enable no-descending-specificity */
