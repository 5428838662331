.colorpicker-preview {
  height: 35px;
  width: 35px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:hover {
    cursor: pointer;
  }
}

