.payments-analytics-nav-container {
  margin-left: auto;
  margin-right: auto;
  width: 225px;
}

.payments-analytics-nav-bar {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  .col {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}
