// General Styles

.btn-soft {
  border-color: $ti-gray-three;
  color: $body-color;
}

.red-when-hovering {
  color: $body-color;
}

.red-when-hovering:hover {
  color: $danger;
}

.blue-top-border {
  border-top: 5px solid $ti-brand-blue;
}

.gray-top-border {
  border-top: 5px solid $ti-gray-four;
}

.ti-card {
  padding: 0.5rem !important;

  .team-member-email {
    max-width: 83%;
  }
}

.ti-data-card {
  padding: 5px;
  margin: 0;
  font-size: 14px;
  background-color: $white;
  border: 1px solid $ti-gray-one;
  text-align: center;
}

.floating-action-button {
  position: absolute;
  right: 10px;
  top: 8px;
}

.inapp_background {
  background-color: transparent;
}

a {
  color: $link-color;
  text-decoration:none;
}

a .card {
  color: $body-color;
}

a .card:hover {
  background-color: $ti-gray-five;
}

a:hover {
  color: $link-hover-color;
  text-decoration:none;
}

.gray-link {
  color: $light-grey-body-text-color;
}

.gray-link:hover {
  color: $body-color;
}

.dark-link {
  color: $body-color;
}

.dark-link:hover {
  color: $ti-blue-two;
}

.dark-link-underline {
  color: $body-color;
}

.dark-link-underline:hover {
  color: $body-color;
  text-decoration:underline;
}

.dark-link-hover:hover {
  color: $body-color;
}

.inherit-link {
  color: inherit;
}

.inherit-link:hover {
  color: $ti-blue-two;
}

.alert-notice {
  color: $alert-notice-text-color;
  background-color: $alert-notice-bg-color;
  border-color: $alert-notice-border-color;
}

.alert-notice h4 {
  color: $alert-notice-text-color;
}

.alert-link {
  font-weight: 700;
  text-decoration: underline;
}

.bold_section_title {
  color: $bold-section-title-color;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  text-shadow: 0 1px 0 $bold-section-title-shadow-color;
}

.title-line {
  border-bottom: 1px solid $body-text-emphasis-color;
}

.old_please_subscribe_bar {
  padding: 20px 30px;
  margin-top: 10px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 900;

  a {
    color: $light-green-hover-color;
  }

  a:hover {
    color: $mid-green-hover-color;
  }
}

.ti_block {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  .button_row {
    margin-top: 30px;

    a {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.stackable_col {
  margin-bottom: 40px;
}

.clickable:hover {
  cursor: pointer;
}

.clickable-card:hover {
  cursor: pointer;
  background-color: $ti-gray-five;
}

.drag-handle-container {
  cursor: move;
  cursor: grabbing;
  width: 30px;
  padding: 5px 0;
  font-size: 15px;
}

.drag-handle-container-indent {
  margin-left: 30px;
}

@include media-breakpoint-up(lg) {
  .stackable_col {
    margin-bottom: 0;
  }

  .ti_block {
    .single_indent {
      padding-left: 90px;
      padding-right: 90px;
    }

    .double_indent {
      padding-left: 180px;
      padding-right: 180px;
    }
  }
}


// Checkout Layout


.checkout_box {
  background-color: white;
  padding: 15px 0;
  margin: 5px 0;
  font-size: 17px;

  .table-container {
    padding: 0 10px;
  }

  .table {
    margin-bottom: 0;

    tbody {
      tr.product_line td {
        padding-top: 0;
        border-top: 0;

        .bold_line {
          font-size: 20px;
          font-weight: 700;
        }
      }

      tr.total_line td {
        padding-bottom: 0;
        font-weight: 700;
      }
    }
  }

  .total_span {
    margin-right: 15px;
  }
}

.cta_line {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cta_line_right {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.update_billing {
  padding-top: 20px;
  font-size: 16px;
}

.fine_print {
  padding-top: 40px;
  margin-bottom: 20px;
}

.plan_desc {
  font-size: 17px;
}


// Account layout


.bold_inline {
  font-size: 20px;
  font-weight: 700;
  display: inline;
}


// Admin layout


.admin_stat_box {
  font-size: 14px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
}

.report_file_tile {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 177px;
  height: 177px;
}

.report_file_orphaned_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 0 0 / 25%);
}

.report_file_missing_tile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #555;

  .centered_text {
    color: white;
    text-align: center;
    margin-top: 80px;
  }
}

#trashed-row, #abandoned-device {
  color: #bababa;
  font-style: italic;

  a {
    color: #bababa;
    text-decoration: underline;
  }
}

.admin_data_row {
  padding: 4px 0;

  .col-md-2 {
    font-weight: 700;
  }

  .heading {
    font-weight: 700;
  }
}

.user_data_row {
  margin: 12px 0;

  .form_title {
    font-weight: 700;
    margin: 8px 0;
  }

  .heading {
    margin-top: 15px;
    font-weight: 700;
  }

  .indent {
    margin: 8px 0;
  }
}

.scaled_logo {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.form_grid {
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .footnote {
    margin: 5px 10px;
    font-style: italic;
    font-size: 15px;
  }
}

.ti_table_row {
  display: table-row;
}

.ti_table {
  display: table;
  width: 100%;
  max-width: 100%;
  color: $body-color;

  a.ti_table_row {
    color: $body-color;
  }

  a.ti_table_row:hover {
    color: $ti-table-text-hover-color;
  }
}

.ti_striped {
  .ti_table_row:nth-of-type(odd) {
    background-color: $ti-table-shaded-bg-color;
  }
}

.ti_table_cell {
  display: table-cell;
  border-top: 1px solid #ddd;
  padding: 8px;
}

.ti_table_header_row {
  display: table-row;
  font-weight: 700;

  .ti_table_cell {
    border-top: 0;
  }
}

.ti_button_row {
  margin-top: 5px;
  margin-bottom: 5px;

  .small_indent {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 992px) {
  .ti_button_row {
    .small_indent {
      padding-top: 10px;
    }

    .right_col {
      text-align: right;
    }

    .bold_section_title {
      padding-top: 12px;
    }
  }
}

.radioline {
  margin-top: 10px;
  margin-bottom: 10px;
}

.admin_page_header {
  margin-top: 20px;
  margin-bottom: 20px;

  .title {
    font-weight: 500;
    font-size: 36px;
    line-height: initial;
    display: inline-block;
  }

  .button_group {
    float: right;
  }

  p {
    margin-top: 15px;
    font-size: 17px;
  }
}

.admin_section_header {
  margin-top: 15px;
  margin-bottom: 10px;

  .title {
    font-weight: 500;
    font-size: 27px;
    line-height: initial;
    display: inline-block;
  }

  .button_group {
    float: right;
  }
}

.row {
  .right_col {
    text-align: right;
  }
}

#device-user-spacing {
  margin-top: 15px;
}

.compact_fonts {
  font-size: 15px;
}

.delete_button {
  a { /* stylelint-disable-line no-descending-specificity */
    color: #d00;
  }

  a:hover { /* stylelint-disable-line no-descending-specificity */
    color: #f00;
  }
}

.bouncing_email {
  display: inline-block;
  font-weight: 700;
  color: #d00;
}

.lifetime_inspections {
  display: inline-block;
  font-weight: 700;
}

.lifetime_inspections_row {
  margin-left: 20px;
  margin-right: 20px;
}

.getting_started_spacing {
  margin-top: 30px;
}

.getting_started_cta_spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

.inapp_frame {
  height: 100%;

  .vertical_center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  #media {
    margin: auto;
  }
}

.bottom_space {
  margin-bottom: 30px;
}

.hidden_button_container {
  .hidden_button {
    visibility: hidden;
  }

  &:hover {
    .hidden_button {
      visibility: inherit;
    }
  }
}