.cropper-controls {
  .label {
    cursor: pointer;
  }

  .placeholder_profile_image {
    width: 160px;
    height: 160px;
  }

  .progress {
    display: none;
    margin-bottom: 1rem;
  }

  .alert {
    display: none;
    max-width: 160px;
  }

  .img-container img {
    max-width: 100%;
  }
}

.cropper-view-box, .cropper-face {
  border-radius: 50%;
}

.placeholder_profile_image_frame {
  position: relative;

  #profile-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 160px;
    line-height: 160px;
  }
}
