.job_calendar_tile {
  position: relative;
  box-shadow: 0 2px 1px $ti-drop-shadow;
  border-radius: 0.25rem;
  margin-bottom: 1.0rem;
  background-color: white;
  overflow: hidden;

  .tile_image {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }

  .assignments {
    position: absolute;
    top: 0;
    padding: 8px 12px;
    text-align: left;

    img {
      box-shadow: 0 2px 3px rgb(0 0 0 / 50%);
    }

    svg{
      filter: drop-shadow(0 2px 3px rgb(0 0 0 / 50%));
    }
  }

  .job_desc {
    position: relative;
    width: 100%;
    text-align: left;
    line-height: 1.4;
    padding: 1rem 1.25rem 1.25rem;

    .color_box {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
    }

    .time_box {
      min-width: 120px;
      text-align: right;
    }

    #client-name {
      font-weight: 700;
    }

    #property-address {
      font-weight: 400;
    }

    #inspection-type {
      padding-top: 4px;
    }
  }
}

.conflict_tile {
  box-shadow: 0 2px 1px $ti-drop-shadow;
  border-radius: 0.25rem;
  margin-bottom: 1.0rem;
  background-color: white;
  padding: 1rem 1.25rem 1.25rem;

  .time_box { /* stylelint-disable-line no-descending-specificity */
    min-width: 120px;
    text-align: right;
  }

  #title {
    font-weight: 700;
  }
}