// Pricing & Signup page

.pricing_desc {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.price_line {
  margin-top: 20px;
  font-weight: 500;
  font-size: 36px;
  color: $pricing-grey;
  line-height: 36px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);

  .green {
    color: $ti-green;
  }

  .cost {
    font-size: 72px;
    line-height: 55px;
  }

  .plus {
    vertical-align: super;
    margin-right: 10px;
  }

  .align-top {
    vertical-align: top!important;
  }
}

.pricing_fine_print {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  color: $pricing-grey;
  line-height: 36px;
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
}

@include media-breakpoint-only(md) {
  .pricing-box-container {
    margin-left: 80px;
    margin-right: 80px;
  }
}
