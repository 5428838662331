.autocomplete-bar-container {
  position: relative;

  input {
    padding-right: 2.5rem;
  }

  .spinner-icon {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-size: 1.0rem;
    color: $body-color;
  }
}
