.payments-audit-list {
  .card-footer, .card-header {
    font-weight: 700;
  }

  .mobile-label {
    margin-top: 0.5rem;
    font-weight: 700;
  }
}

@include media-breakpoint-up(lg) {
  .payments-audit-list {
    .value_box {
      margin-left: 10px;
      width: 80px;
      text-align: right;
    }

    .date_box {
      width: 145px;
    }

    .mobile-label {
      display: none;
    }
  }
}
