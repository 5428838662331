.call-to-action {
  min-width: 230px;
}

.call-to-action-buttons {
  display: flex !important;

  .btn {
    margin-left: 0.25rem;
  }

  .btn:first-child {
    margin-left: 0;
  }

  .btn:last-child {
    flex-grow: 1 !important;
  }
}

@include media-breakpoint-up(sm) {
  .call-to-action-buttons {
    justify-content: flex-end !important;

    .btn:last-child {
      flex-grow: 0 !important;
      min-width: 230px;
    }
  }
}
