
.isn_actions {
  padding-top: 15px;
  padding-bottom: 15px;
}

#isn-action-spinner-frame {
  padding: 40px 0;
}

#remove-button {
  color: $red-text-button-color;
}

.order_type {
  padding: 8px 15px;

  .form_title {
    font-weight: 700;
    margin: 8px 0;
  }
}

.order_type:nth-of-type(even) {
  background-color: $alternating-bg-dark-color;
}

.order_type:nth-of-type(odd) {
  background-color: $alternating-bg-light-color;
}

select.needs_attention {
  background-color: $needs-attention-color;
}
