// Angle/Bootstrap overrides

body, .wrapper .section-container {
  background-color: $content-bg;
}

.page-header {
  padding-bottom: 0.5938rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid $ti-gray-one;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 1.25rem;
  background-color: inherit;
}

.modal-header h5 {
  font-size: 1.25rem;
}

.btn {
  border-radius: 3px;
  font-size: 13px;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-ti-gray-one);
}

// Override the custom bootstrap switch color
.form-check-input:checked, .form-check-input.is-valid:checked {
  background-color: $ti-green;
  border-color: $ti-green;
}

.form-check-input:not(:disabled):active {
  background-color: $ti-green-two;
  border-color: $ti-green-two;
}

.card {
  margin-bottom: 1.25rem;
  box-shadow: 0 2px 1px $ti-drop-shadow;
  border: 0;

  .table {
    margin-bottom: 0;
  }
}

.card > .list-group + .card-footer {
  border-top: 1px solid $card-border-color;
}

.card .card > .list-group + .card-footer {
  border-top: 0;
}

// If the card is embedded in another card put a border back in
.card .card {
  border: 1px solid $card-border-color;

  .card-header {
    background-color: $ti-gray-one;
  }
}

.dropdown-menu {
  box-shadow: 0 2px 1px $ti-drop-shadow;
}

// Override some styles to revert to a bootstrap 4 appearance

.btn-primary, .btn-ti-green {
  color: #fff;

  &:focus, &:hover, &:disabled {
    color: #fff;
  }
}

.form-control, .form-select {
  --bs-border-color: var(--bs-ti-gray-six);

  background-color: #fff;

  &:focus {
    background-color: #fff;
  }
}

// Override validation colors
.was-validated .form-select:valid, .form-select.is-valid, .form-control.is-valid, .form-check-input.is-valid ~ .form-check-label {
  --bs-form-valid-border-color: var(--bs-ti-gray-six);

  color: inherit;

  /* stylelint-disable-next-line no-descending-specificity */
  &:focus {
    box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color;
  }
}

hr {
  opacity: 0.6;
}

.form-label {
  font-weight: 600 !important;
}

:root {
  --bs-body-bg: white;
}
