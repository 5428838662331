
// Analytics controller styles

.date-nav-row {
  text-align: right;

  ul {
    margin-bottom: 15px;
  }

  li {
    display: inline;
    margin-left: 15px;
  }
}

.metric-menu {
  ul {
    margin: 20px 0;
  }
}

.heat-cell {
  text-align: center;
  font-weight: 700;
  padding: 5px 0;
}

.disclaimer {
  color: $body-text-emphasis-color;
  margin: 15px 0;
}

.table {
  .is-subrow {
    td {
      border-top: 1px solid #eee;
    }

    .heading {
      padding-left: 20px;
    }
  }

  .hidden-form a {
    color: $body-color;
  }
}