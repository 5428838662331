// Applies to full width layouts or not
.content-heading {
  display: flex !important;
  flex-direction: column !important;

  .title {
    width: 100%;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .back-button {
    font-size: 1.2rem;
    text-decoration: none !important;
    margin-right: 0.5rem;
  }

  .buttons {
    display: flex !important;
  }

  .buttons > div, .buttons > a {
    margin-top: 1rem;
    margin-left: 0.5rem !important;
  }

  small {
    color: white;
    margin-top: 5px;
    font-size: 14px;
  }

  .badge {
    font-size: 12px;
  }
}

// Applies only to full width layouts
.content-wrapper {
  .content-heading {
    background-color: $ti-blue-three;
    border-bottom: 0;
    color: white;

    .title {
      a {
        color: white;
        text-decoration: underline;
      }
    }

    small {
      color: white;
    }

    .badge {
      font-size: 12px;
    }

    .search-bar-container {
      .search-icon {
        left: 0.5rem;
        top: 0.7rem;
        color: white;
      }

      input {
        background-color: transparent;
        color: white;
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .spinner-icon {
        right: 0.5rem;
        top: 0.7rem;
        color: white;
      }

      // fixes for the user search bar search results
      .list-group {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 200;
        font-size: 15px;
      }
    }
  }
}

@media (min-width: 768px) {
  .content-heading {
    flex-direction: row !important;

    .title {
      width: auto;
      margin-right: 1rem;
    }

    .buttons {
      margin-left: auto;
    }

    .buttons > div, .buttons > a {
      margin-top: 0;
    }
  }
}

@media print {
  .content-heading,
  .content-heading * {
    display: none !important;
  }
}
