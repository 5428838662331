.note-card {
  .profile-image-container {
    min-width: 32px;
    margin-right: 0.5rem !important;
  }

  .name {
    font-weight: 700;
  }
}
