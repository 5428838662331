.saved_comment_editor {
  #saved-comment-control-row {
    ul.pagination {
      margin-bottom: 0;
      margin-left: 1rem;
    }

    margin-bottom: 1rem;
  }

  .sort_date_col {
    min-width: 100px;
    text-align: right;
  }
}
