// Login layout

.login_background {
  background: linear-gradient(to bottom, #000cbb, #000053);
  background-attachment: fixed;
}

.login-container-fluid {
  height:100%;
  display:table;
  width: 100%;
  padding: 20px;
}

.login-row-fluid {
  height: 100%;
  display:table-cell;
  vertical-align: middle;
}

#signup {
  max-width: 800px;
}

.login_black_box {
  color: #999;
  background: rgb(0 0 0 / 75%);
  padding: 20px;
  max-width: 350px;
  margin: 0 auto;
  position: relative;

  .floaty {
    position: absolute;
    top: 20px;
    left: 15px;
  }

  label.error {
    color: #df0810;
    padding: 5px 15px;
    display:block;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    border-radius: 0;
    border: 1px solid #dadada;
    display: block;
    height: 30px;
    width: 250px;
    margin: 0 auto;
  }

  label {
    color: #ddd;
    font-size: 16px;
  }

  label:hover {
    color: $light-green-hover-color;
  }
}

.login_box_title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 0 30px;
}

.login_box_subtitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 30px;
}

.check_col {
  width: 30px;
  float: left;

  .form-control {
    height: 14px;
  }
}

.login_trailing_item {
  text-align: center;
  padding-top: 20px;
}

.login_trailing_item a {
  color: white;
  text-decoration: underline;
}

.login_trailing_item a:hover {
  color: $light-green-hover-color;
}

.login_form form {
  margin: 0;
}

.login_form input[type="submit"] {
  display: block;
  margin: 30px auto 0;
  padding: 8px 35px;
}

.login_cta_row {
  padding-top: 30px;
  text-align: center;
}

.set-password-box {
  padding: 20px;
  margin-top: 50px;
  background-color: white;
}

#back-button {
  display: block;
  height: 32px;
  width: 27px;
  padding: 0;
}

#button-arrow {
  margin-top: -4px;
  padding-left: 5px;
}
