// Application Navigation Bar Header

.topnavbar-wrapper {
  z-index: 1050;
  position: relative;
}

.navbar-blue {
  background-color: $ti-brand-blue;
  padding-top: 0;
  padding-bottom: 0;

  .nav-item {
    font-size: 17px;
  }

  .nav-item, .dropdown-item {
    a, a.nav-link {
      color: white;

      &:hover {
        color: $light-green-hover-color;
      }
    }
  }

  .dropdown-menu {
    background-color: $ti-blue-seven;
    border: 0;
  }

  .dropdown-item:hover {
    background-color: transparent;
  }

  #my-account {
    padding-top: 0;

    .dropdown-toggle::after {
      display: none !important;
    }
  }
}

.admin-nav-items {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;

  .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

#nav-search-bar {
  z-index: 1050;
  background-color: white;
  position: absolute;
  inset: 1px 0;
  margin: 0;
  padding: 0;

  .close-button {
    padding: 0.9rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $body-color;
  }

  .close-button:hover {
    color: $danger;
  }

  .search-bar-container {
    width: 100%;
    height: 100%;

    .search-icon {
      z-index: 200;
      top: 50%;
      transform: translateY(-50%);
    }

    .spinner-icon {
      z-index: 200;
      top: 50%;
      transform: translateY(-50%);
      right: 2.5rem;
    }
  }

  .form-control {
    border: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .form-control:focus {
    box-shadow: none;
  }

  // fixes for the user search bar search results
  .list-group {
    position: absolute;
    top: 100%;
    width: 100%;
  }
}
