/* stylelint-disable selector-type-no-unknown */
@import '~trix/dist/trix.css';

.trix-button-group--file-tools {
  display: none !important;
}

.trix-button--icon-strike {
  display: none !important;
}

.trix-button--icon-code {
  display: none !important;
}

trix-editor {
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
  border: 1px solid var(--bs-ti-gray-six);
}

.small-trix trix-editor {
  height: 25vh;
  max-height: 25vh;
}

trix-toolbar .trix-button {
  color: $ti-dark-text;
  border: none;
}

trix-toolbar .trix-button.trix-active {
  color: $ti-dark-text;
  background: $ti-gray-three;
}

trix-toolbar .trix-button-group {
  border: 1px solid var(--bs-ti-gray-six);
}

#hide-html-controls .trix-button-group--text-tools {
  display: none !important;
}

#hide-html-controls .trix-button-group--block-tools {
  margin-left: 0;
}

#hide-html-controls .trix-button-group--block-tools button {
  display: none !important;
}

#hide-html-controls #raw-editor-toggle {
  display: none !important;
}

#insert-liquid-tags-select {
  font-size: 1em;
  appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
}

/* stylelint-enable selector-type-no-unknown */
