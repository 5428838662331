.nav-container {
  display: none;
  width: 375px;
}

.job-nav-bar {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  .col {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

.mobile-nav-container {
  margin: -20px -15px 15px;
  padding: 15px;
  background-color: white;
}

// when the navigation container is present, if the page has not specified any additional buttons to showing the content heading, a spacer is included so that the navigation container doesn't jump left to right when switching pages
.job-nav-spacer {
  width: 43px;
}

@media (min-width: 768px) {
  .nav-container {
    display: block;
  }

  .mobile-nav-container {
    display: none;
  }
}
